import React, {useState} from "react";
import PropertyModel from "api/model/PropertyModel";
import {PropertyPopupsMapModel} from "components/Search/PopupSearch/PopupList/index";
import PropertyGridRow from "components/Search/PopupSearch/PopupList/PropertyGrid/PropertyGridRow";

const GRID_ITEM_WIDTH_PX = 250;
const GRID_ITEMS_MARGIN_MIN_PX = 10;

interface PropertyGridProps {
    gridWidthPx: number,
    properties: PropertyModel[],
    popupsMap: PropertyPopupsMapModel,
}

export interface SelectedPropertyModel {
    property: PropertyModel,
    rowIndex: number,
}

const PropertyGrid = (props: PropertyGridProps) => {
    const {gridWidthPx, popupsMap, properties} = props;
    const [selectedProperty, setSelectedProperty] = useState<SelectedPropertyModel | undefined>();
    const dynamicProperties = properties.filter(property => popupsMap[property.id] && popupsMap[property.id].length > 0);
    dynamicProperties.reverse();
    const countBeforeMargin = gridWidthPx / GRID_ITEM_WIDTH_PX;
    const margin = countBeforeMargin * GRID_ITEMS_MARGIN_MIN_PX;
    const propertiesInRow = Math.floor((gridWidthPx - margin) / GRID_ITEM_WIDTH_PX);
    const totalRows = Math.ceil(properties.length / propertiesInRow);
    const rows: PropertyModel[][] = [];
    rowLoop:
    for (let rowIdx = 0; rowIdx < totalRows; rowIdx++) {
        if (!rows[rowIdx]) {
            rows[rowIdx] = [];
        }
        for (let propertyIdx = 0; propertyIdx < propertiesInRow; propertyIdx++) {
            const property = dynamicProperties.pop();
            if (!property) {
                break rowLoop;
            }
            rows[rowIdx].push(property);
        }
    }
    return (
        <div className="popup-search-property-grid">
            {
                rows.map((row, idx) => (
                    <PropertyGridRow
                        rowIndex={idx}
                        properties={row}
                        popupsMap={popupsMap}
                        selectedProperty={selectedProperty}
                        setSelectedProperty={setSelectedProperty}
                    />
                ))
            }
        </div>
    );
};

export default PropertyGrid;