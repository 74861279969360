import React from 'react';
import urlSlug from "url-slug";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PopupModel from "api/model/PopupModel";
import PropertyModel from 'api/model/PropertyModel';
import {ProductCategory} from "api/model/ProductCategory";
import ItemTag from "components/common/ItemTag";
import PopupPriceTag from "components/Search/PopupSearch/PopupPriceTag";
import "./styles.css";

const MAX_CATEGORY_COUNT = 4;

interface PropertyGridRowPopupProps {
    popup: PopupModel,
    property: PropertyModel,
}

const PropertyGridPopupItem = (props: PropertyGridRowPopupProps) => {
    const {popup, property} = props;
    const {t} = useTranslation();
    const {requestedProductCategories} = popup;
    let categoryOverflow = 0;
    let snippedCategories: ProductCategory[] = requestedProductCategories || [];
    if (snippedCategories.length > MAX_CATEGORY_COUNT + 1) {
        categoryOverflow = snippedCategories.length - MAX_CATEGORY_COUNT;
        snippedCategories = snippedCategories.slice(0, MAX_CATEGORY_COUNT);
    }
    return (
        <Link
            target="_blank"
            to={`/popup/${popup.id}/${urlSlug(`${property.name} ${popup.name}`)}`}
            className="property-grid-popup-item"
        >
            <div className="property-grid-popup-item__logo">
                <img alt={popup.name} src={popup.keyPhotoUrl}/>
            </div>
            <div className="property-grid-popup-item-data">
                <div className="property-grid-popup-item-data__header">
                    {popup.name}
                </div>
                <div className="property-grid-popup-item-data__info">
                    <div className="property-grid-popup-item-data__description">
                        {popup.descriptionShort}
                    </div>
                    <div className="property-grid-popup-item-data__meta">
                        <div className="property-grid-popup-item-data__price">
                            <PopupPriceTag prices={popup.prices}/>
                        </div>
                        {
                            snippedCategories.length > 0 && (
                                <div className="property-grid-popup-item-data__tags">
                                    {
                                        snippedCategories.map(cat => (
                                            <ItemTag key={cat} size="M" type="Success">
                                                {t(`product.category.${cat}`)}
                                            </ItemTag>
                                        ))
                                    }
                                    {
                                        categoryOverflow > 0 && (
                                            <ItemTag key="categories-overflow" size="M" type="Success">
                                                + {categoryOverflow}
                                            </ItemTag>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default PropertyGridPopupItem;