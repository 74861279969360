import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {useLocationStore} from "store/locationStore";
import {CountryType} from "consts/CountryConsts";
import PopupModel from "api/model/PopupModel";
import PopupListItem from "components/Search/PopupSearch/PopupList/PopupListItem";
import NoResults from "components/Search/PopupSearch/PopupList/NoResults";
import PropertyModel from "api/model/PropertyModel";
import ComingSoonItem from "components/Search/PopupSearch/PopupList/ComingSoonItem";
import PropertyItem from "components/Search/PopupSearch/PopupList/PropertyGrid/PropertyGridRow/PropertyItem";
import useElementSize from "lib/useElementSize";
import "./styles.css";
import PropertyGrid from "components/Search/PopupSearch/PopupList/PropertyGrid";

interface PropertiesMapModel {
    [propertyId: number]: PropertyModel
}

export interface PropertyPopupsMapModel {
    [propertyId: number]: PopupModel[],
}

interface PopupListProps {
    popups?: PopupModel[],
    properties?: PropertyModel[],
}

const sortPropertiesByCountry = (userCountryIso: CountryType, properties: PropertyModel[]) => {
    const userCountryProperties: PropertyModel[] = [];
    const restProperties: PropertyModel[] = [];
    for (const property of properties) {
        if (property.location.countryIso === userCountryIso) {
            userCountryProperties.push(property);
        } else {
            restProperties.push(property);
        }
    }
    return [...userCountryProperties, ...restProperties];
}

const sortPopupsByCountry = (userCountryIso: CountryType, popups: PopupModel[] = [], propertyMap: PropertiesMapModel) => {
    const userCountryPopups: PopupModel[] = [];
    const restPopups: PopupModel[] = [];
    for (const popup of popups) {
        const property = propertyMap[popup.propertyId];
        if (property.location.countryIso === userCountryIso) {
            userCountryPopups.push(popup);
        } else {
            restPopups.push(popup);
        }
    }
    return [...userCountryPopups, ...restPopups];
}

const PopupList = (props: PopupListProps) => {
    const {t} = useTranslation();
    let {popups = [], properties} = props;
    const [ref, elementSize] = useElementSize();
    const userCountry = useLocationStore(state => state.location?.countryIso) || 'EE';
    if (!properties || properties.length < 1) {
        return <></>;
    }
    properties = sortPropertiesByCountry(userCountry, properties);
    const propertiesMap = properties.reduce((previous, current) => {
        previous[current.id] = current;
        return previous;
    }, {} as PropertiesMapModel);
    popups = sortPopupsByCountry(userCountry, popups, propertiesMap);
    const popupsMap = properties.reduce((previousValue, currentValue) => {
        previousValue[currentValue.id] = popups.filter(popup => popup.propertyId === currentValue.id);
        return previousValue;
    }, {} as PropertyPopupsMapModel);

    const comingSoonProperties = properties.filter(prop => prop.isComingSoon);
    return (
        <>
            <div ref={ref} className="popup-search-list">
                {
                    elementSize.width > 0 && <PropertyGrid gridWidthPx={elementSize.width} properties={properties} popupsMap={popupsMap} />
                }
                {/*{*/}
                {/*    popups.length > 0 ? popups.map(popup => {*/}
                {/*        const property = propertiesMap[popup.propertyId];*/}
                {/*        if (property) {*/}
                {/*            return <PopupListItem key={popup.id} popup={popup} property={property}/>;*/}
                {/*        }*/}
                {/*    }) : <NoResults/>*/}
                {/*}*/}
            </div>
            <div className="popup-search-list-divider">
                <hr/>
                <h2>
                    {t('popup.search.list.coming.soon.section.header')}
                </h2>
            </div>
            <div className="popup-search-list-coming-soon">
                {
                    comingSoonProperties.map((property, index) => (
                        <ComingSoonItem key={property.id} property={property} index={index + 1}/>
                    ))
                }
            </div>
        </>
    );
}

export default PopupList;