import React, {useState} from "react";
import PropertyModel from "api/model/PropertyModel";
import {PropertyPopupsMapModel} from "components/Search/PopupSearch/PopupList/index";
import PropertyItem from "components/Search/PopupSearch/PopupList/PropertyGrid/PropertyGridRow/PropertyItem";
import {SelectedPropertyModel} from "components/Search/PopupSearch/PopupList/PropertyGrid/index";
import "./styles.css";
import PropertyGridRowPopups
    from "components/Search/PopupSearch/PopupList/PropertyGrid/PropertyGridRow/PropertyGridRowPopups";

interface PropertyGridRowProps {
    rowIndex: number,
    properties: PropertyModel[],
    popupsMap: PropertyPopupsMapModel,
    selectedProperty?: SelectedPropertyModel,
    setSelectedProperty: (selected?: SelectedPropertyModel) => void,
}

const PropertyGridRow = (props: PropertyGridRowProps) => {
    const {rowIndex, properties, popupsMap, setSelectedProperty, selectedProperty} = props;
    const setProperty = (property?: PropertyModel) => {
        if (property) {
            setSelectedProperty({
                rowIndex,
                property,
            });
        } else {
            setSelectedProperty();
        }
    }
    return (
        <div className="popup-search-property-grid-row">
            <div className="popup-search-property-grid-row__properties">
                {
                    properties.map(property => (
                        <PropertyItem
                            property={property}
                            popupsMap={popupsMap}
                            selectProperty={setProperty}
                            isSelected={property.id === selectedProperty?.property.id}
                        />
                    ))
                }
            </div>
            {
                selectedProperty?.rowIndex === rowIndex && (
                    <PropertyGridRowPopups property={selectedProperty.property} popups={popupsMap[selectedProperty.property.id]} />
                )
            }
        </div>
    );
};

export default PropertyGridRow;
