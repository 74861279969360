import {RefObject, useEffect, useRef, useState} from 'react';

interface ElementSize {
    width: number,
    height: number,
}

const useElementSize = (): [RefObject<HTMLDivElement>, ElementSize] => {
    const ref = useRef<HTMLDivElement>(null);
    const [elementSize, setElementSize] = useState<ElementSize>({
        width: 0,
        height: 0,
    });
    useEffect(() => {
        if (ref.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                setElementSize({
                    width: ref.current?.offsetWidth || 0,
                    height: ref.current?.offsetHeight || 0,
                });
            });
            resizeObserver.observe(ref.current);
            return () => resizeObserver.disconnect();
        }
    }, [ref.current]);

    return [ref, elementSize];
};

export default useElementSize;
