import React, {useRef} from 'react';
import PropertyModel from "api/model/PropertyModel";
import BusinessIcon from '@mui/icons-material/Business';
import PlaceIcon from '@mui/icons-material/Place';
import StorefrontIcon from '@mui/icons-material/Storefront';
import {PropertyPopupsMapModel} from "components/Search/PopupSearch/PopupList/index";
import PopupModel from "api/model/PopupModel";
import ItemTag from "components/common/ItemTag";
import "./styles.css";

interface PropertyItemProps {
    property: PropertyModel,
    popupsMap: PropertyPopupsMapModel,
    selectProperty: (property?: PropertyModel) => void,
    isSelected: boolean,
}

const PropertyItem = (props: PropertyItemProps) => {
    const {
        property,
        isSelected,
        popupsMap,
        selectProperty,
    } = props;
    const propertyRef = useRef<HTMLDivElement>(null);
    const select = () => {
        if (isSelected) {
            selectProperty();
        } else {
            selectProperty(property);
            setTimeout(() => {
                if (propertyRef.current) {
                    propertyRef.current.scrollIntoView({behavior: "smooth"});
                }
            }, 10);
        }
    }
    const popups: PopupModel[] = popupsMap[property.id] || [];
    let lowestPrice: number = 0;
    let highestPrice: number = 0;
    let currencyIcon: string = "€";
    for (const {prices} of popups) {
        for (const {price, currency} of prices) {
            if (lowestPrice === 0 || lowestPrice > price) {
                lowestPrice = price;
            }
            if (highestPrice < price) {
                highestPrice = price;
            }
            currencyIcon = currency;
        }
    }
    return (
        <>
            <div ref={propertyRef} onClick={select} className="popup-search-property">
                <h2>
                    <BusinessIcon fontSize="large" color="secondary"/>
                    {property.name}
                </h2>
                <img alt={property.name} src={property.logoUrl || '/static/icons/property.svg'}/>
                <div className="popup-search-property__data">
                    <div className="popup-search-property__data-middle">
                        <div className="popup-search-property__location">
                            <PlaceIcon color="secondary"/>
                            {property.location.town}
                        </div>
                        <div className="popup-search-property__popup-count">
                            <StorefrontIcon color="secondary" fontSize="medium"/>{popups.length}
                        </div>
                    </div>
                    <div className="popup-search-property__prices">
                        {
                            lowestPrice > 0 && (
                                <>
                                    <ItemTag size="M">
                                        {currencyIcon}{lowestPrice}
                                    </ItemTag>
                                    {
                                        highestPrice !== lowestPrice && (
                                            <>
                                                &nbsp;-&nbsp;
                                                <ItemTag size="M">
                                                    {currencyIcon}{highestPrice}
                                                </ItemTag>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
                {
                    isSelected && (
                        <div className="popup-search-property__selected">
                            <span className="popup-search-property__selected-arrow"/>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default PropertyItem;