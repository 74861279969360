import React from "react";
import PopupModel from "api/model/PopupModel";
import PropertyModel from "api/model/PropertyModel";
import PropertyGridPopupItem from "./PropertyGridPopupItem";
import "./styles.css";

interface PropertyGridRowPopupsProps {
    popups: PopupModel[],
    property: PropertyModel,
}

const PropertyGridRowPopups = (props: PropertyGridRowPopupsProps) => {
    const {popups, property} = props;
    return (
        <div className="popup-search-property-grid-row-popups">
            {
                popups.map(popup => (
                    <PropertyGridPopupItem
                        key={popup.id}
                        popup={popup}
                        property={property}
                    />
                ))
            }
        </div>
    );
};

export default PropertyGridRowPopups;